import { useDispatch, useSelector } from "react-redux";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import {
  fetchIndividualProfile,
  individualUserSelector,
} from "../api/individualUser";
import { useEffect } from "react";

const PrivateRoutes = () => {
  const { isAuthenticate, individualtoken } = useSelector(
    individualUserSelector
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (individualtoken) {
      dispatch(fetchIndividualProfile(individualtoken));
    }
  }, [isAuthenticate]);



  return isAuthenticate ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;

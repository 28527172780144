import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";
import { fetchIndividualProfile } from "./individualUser";

// Retrieve token from local storage
const token = localStorage.getItem("token") || null;

// Define the initial state
export const initialState = {
  loading: false,
  hasErrors: false,
  explore: [],
  currentExplore: {},
  individualtoken: token,
  currentAdd: {},
  adds: [],
};

// Redux slice for explore management
export const exploreSlice = createSlice({
  name: "explore",
  initialState,
  reducers: {
    getExploreLoading: (state) => {
      state.loading = true;
      state.hasErrors = false; // Reset error state on new request
    },
    getCurrentExploreSuccessfull: (state, { payload }) => {
      state.loading = false;
      state.currentExplore = payload?.explore || {};
      state.hasErrors = false;
    },
    getAllExploreSuccessful: (state, { payload }) => {
      state.loading = false;
      state.explore = payload?.all_explore || [];
      state.hasErrors = false;
    },
    getCurrentAddSuccessful: (state, { payload }) => {
      state.loading = false;
      state.currentAdd = payload?.add || {};
      state.hasErrors = false;
    },
    getExploreFailed: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getExploreSuccess: (state, { payload }) => {
      state.loading = false;
      // You might want to add a specific action for success with additional state changes if needed
    },
    getAllAddsSuccessful: (state, { payload }) => {
      state.adds = payload.matchingAdds;
    },
  },
});

// Export actions
export const {
  getExploreLoading,
  getAllExploreSuccessful,
  getCurrentExploreSuccessfull,
  getExploreFailed,
  getExploreSuccess,
  getCurrentAddSuccessful,
  getAllAddsSuccessful,
} = exploreSlice.actions;

// Selector to access explore state
export const exploreSelector = (state) => state.explore;

// Export reducer
export default exploreSlice.reducer;

// Thunks for API calls

// Fetch all explores
// Fetch all exploresg
export const fetchAllExplore = () => async (dispatch) => {
  dispatch(getExploreLoading());
  try {
    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/ioi-users/fetch-events`,
      config
    );
    dispatch(getAllExploreSuccessful(data));
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to fetch explores.";
    console.error(errorMessage); // Log the error message for debugging
    dispatch(getExploreFailed());
  }
};

// Create a new explore
export const createExplore = (value) => async (dispatch) => {
  dispatch(getExploreLoading());

  try {
    const { data } = await axios.post(
      `${keyUri.BACKEND_URI}/ioi-users/create-events`,
      value,
      config
    );

    toast.success(data.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    // Wait for 3 seconds before performing the next operation
    setTimeout(() => {
      // Perform your next operation here
      // Example: Dispatch another action or update the local state
      dispatch(getExploreSuccess(data)); // Example action dispatch
    }, 3000); // 3 seconds delay
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to create explore.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getExploreFailed());
  }
};

export const fetchUserAdds = (values) => async (dispatch) => {
  try {
    // Make an API call to fetch ads
    const { data } = await axios.post(
      `${keyUri.BACKEND_URI}/users/fetch-adds`, // API endpoint
      values, // Data sent in the request body
      config // Config includes headers (e.g., token for authentication)
    );

    // Dispatch action to store the ads data in Redux
    dispatch(getAllAddsSuccessful(data));

    // Optionally show a success toast (uncomment if needed)
    // toast.success(data.message, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   theme: "light",
    // });
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to fetch ads.";

    // Show error toast
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

    // Dispatch failure action
    // dispatch(getExploreFailed());
  }
};

export const saveExplore = (userId, exploreId, token) => async (dispatch) => {
  try {
    // alert(exploreId, "exploreid");

    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/individual-users/save_explore/${userId}`, // Use topicId in the URL
      { exploreId }, // Send userId in the request body
      config // Authorization token in headers
    );

    // Dispatch actions after saving the topic
    dispatch(fetchAllExplore()); // Fetch updated topics
    dispatch(fetchIndividualProfile(token)); // Fetch updated profile

    // Show success toast notification
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    // Show error toast notification
    toast.warning(`${error.response?.data?.message || "Error saving topic"}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const unSaveExplore = (userId, exploreId, token) => async (dispatch) => {
  try {
    const loginConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/individual-users/unsave_explore/${userId}`,
      { exploreId },
      loginConfig
    );
    dispatch(fetchAllExplore());

    dispatch(fetchIndividualProfile(token));

    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const getOneAdd = (id) => async (dispatch) => {
  dispatch(getExploreLoading());
  try {
    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/ioi-users/get-a-add/${id}`,
      config
    );
    dispatch(getCurrentAddSuccessful(data));
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to fetch publish.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getExploreFailed());
  }
};

export const saveAdUser = (userId, addId, token) => async (dispatch) => {
  try {
    // alert(exploreId, "exploreid");

    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/users/save_add_user/${userId}`, // Use topicId in the URL
      { addId }, // Send userId in the request body
      config // Authorization token in headers
    );

    // Dispatch actions after saving the topic
    dispatch(fetchUserAdds({ userId })); // Fetch updated topics
    dispatch(fetchIndividualProfile(token)); // Fetch updated profile

    // Show success toast notification
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    // Show error toast notification
    toast.warning(`${error.response?.data?.message || "Error saving topic"}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const unSaveAdUser = (userId, addId, token) => async (dispatch) => {
  try {
    const loginConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/unsave_add_user/${userId}`,
      { addId },
      loginConfig
    );
    dispatch(fetchUserAdds({ userId })); // Fetch updated topics

    dispatch(fetchIndividualProfile(token));

    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

import React, { useState } from "react";
import "./Publish.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import { individualUserSelector } from "../../api/individualUser";
import { NavLink } from "react-router-dom";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WorkIcon from "@mui/icons-material/Work";

import { toast } from "react-toastify";
import { createPublish } from "../../api/publish";
import { nanoid } from "@reduxjs/toolkit";
import storage from "../../shared/firebaseStorage";
import { useLocation } from "react-router";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const useStyles = makeStyles(() => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
}));

function Addpublish() {
  const { user } = useSelector(individualUserSelector);
  const [document, setDocument] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfPercent, setPdfPercent] = useState(0);
  const [uploadSuccessMsg, setUploadSuccessMsg] = useState("");
  const { pathname } = useLocation();

  const [publish, setPublish] = useState({
    certificate_name: "",
    description: "",
    publish_status: "Active",
     // or "IOIUser"
  });



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleFileInputChange = (event) => {
    const newSelectedFile = event.target.files[0];
    setSelectedFile(newSelectedFile);

    if (newSelectedFile) {
      const isPdf = newSelectedFile.type === "application/pdf";
      if (!isPdf) {
        toast.warning("You can only upload PDF files!", {
          position: "top-center",
          autoClose: 1000,
          theme: "light",
        });
        return;
      }

      const isLt100KB = newSelectedFile.size / 1024 < 100;
      if (!isLt100KB) {
        toast.warning("File size must be less than 100KB", {
          position: "top-center",
          autoClose: 2000,
          theme: "light",
        });
        return;
      }

      const storageRef = ref(
        storage,
        `/Individual Profile Completion/${nanoid() + newSelectedFile.name}`
      );
      const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);

      uploadFile.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPdfPercent(percent);
          if (percent === 100) {
            setUploadSuccessMsg("File uploaded successfully.");
          }
        },
        (err) => {
          console.error(err);
        },
        () => {
          getDownloadURL(uploadFile.snapshot.ref).then((url) => {
            setDocument(url);
          });
        }
      );
    }
  };

  const handleChange = (e) => {
    setPublish({ ...publish, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const { certificate_name, description } = publish;

    if (certificate_name && description && document) {
      const publishInfo = {
        ...publish,
        document, 
        publish_posted_by: user._id, // Ensure this matches your schema
      };
    

      try {
        await dispatch(createPublish(publishInfo));
        toast.success("Publish created successfully");
        navigate(-1);
      } catch (error) {
        toast.error("Failed to create publish");
        console.error("Error creating publish:", error);
      }
    } else {
      toast.warning("Please fill all the fields and upload a document", {
        position: "top-center",
        autoClose: 1000,
        theme: "light",
      });
    }
  };

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/topics">
                  <div
                    className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("topics")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        All Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Mytopics">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  <div className="my-4 mx-0">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      Create Publish
                    </h2>
                  </div>

                  <form onSubmit={submitHandler}>
                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="certificate_name"
                      >
                        Certificate Name
                      </label>
                      <input
                        id="certificate_name"
                        name="certificate_name"
                        type="text"
                        value={publish.certificate_name}
                        onChange={handleChange}
                        placeholder="Enter certificate name"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="description"
                      >
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        value={publish.description}
                        onChange={handleChange}
                        placeholder="Enter description"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      ></textarea>
                    </div>

                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="upload"
                      >
                        Upload a Document
                      </label>
                      <div className="relative flex items-center border-dashed border-2 border-gray-300 rounded p-4">
                        <input
                          id="upload"
                          type="file"
                          accept="application/pdf"
                          onChange={handleFileInputChange}
                          className="absolute inset-0 opacity-0 cursor-pointer"
                        />
                        <div className="flex items-center justify-center w-full h-full text-gray-500">
                          <PictureAsPdfIcon style={{ fontSize: 50 }} />
                          <span className="ml-4 text-lg">
                            Drag & Drop or Browse
                          </span>
                        </div>
                      </div>
                      {pdfPercent > 0 && (
                        <div className="mt-2">
                          <progress value={pdfPercent} max="100" />
                          <span>{pdfPercent}%</span>
                        </div>
                      )}
                      {uploadSuccessMsg && (
                        <div className="mt-2 text-green-600">
                          {uploadSuccessMsg}
                        </div>
                      )}
                    </div>

                    <div className="mb-6">
                      <button
                        type="submit"
                        className="bg-[#0F3D79] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Create Publish
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Addpublish;

import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import "./Explore.css";
import WorkIcon from "@mui/icons-material/Work";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Modal from "@mui/material/Modal";
import AddIcon from "@material-ui/icons/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FormControl } from "@mui/material";
import { Autocomplete } from "@mui/joy";
import { TextField, IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "../../images/comingsoon.png";
import FilterIcon from "@mui/icons-material/FilterList";
import image2 from "../../images/logo.png";
import image3 from "../../images/bharcimg.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import a PDF icon
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function CreateAdd() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [type, setType] = useState("jobs");
  const nav = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deletionId, setDeletionId] = useState("");

  

  const [fileName, setFileName] = useState("");

  //   const handleFileChange = (event) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //       setFileName(file.name);
  //     }
  //   };

  //   const reloadButton = () => {
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 10);
  //   };

  //   useEffect(() => {
  //     dispatch(fetchIOIProfile(token));
  //   }, [token]);

  //   useEffect(() => {
  //     dispatch(fetchPostedJobs(user?._id));
  //   }, [user]);

 
  return (
    <section class="py-8" style={{ fontFamily: "Lato" }}>
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap -mx-3">
          <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
            <div class="w-full mb-6">
              <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                <img
                  class="block mx-auto mb-5 w-28 h-28 rounded-full"
                  src=""
                  alt=""
                />
                <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
                  {/* {user?.name} */}
                </h4>
                <div class="flex items-center justify-center mb-6">
                  <p class="text-md font-semibold text-gray-500">
                    {/* {user?.sub_category} */}
                  </p>
                </div>
              </div>
            </div>
            <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
              <NavLink to="/Conferences">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Conferences
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Competitions">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Competitions
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Otherevents">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Other Events
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Ads">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Ads
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Myevents">
                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  style={{
                    backgroundColor: pathname.includes("Myevents")
                      ? "#CEDDF6"
                      : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      My Events
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/SavedAds">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Saved Ads
                    </h4>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
          {/* <div class="w-full lg:w-[78%] px-3">
          <div class="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl flex items-center justify-center">
            <img class="w-100 h-100 object-contain" src={Image1} />
          </div>
        </div> */}
          <div className="w-full lg:w-[78%] px-3">
            <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
              <div className="md:max-w-6xl mx-auto">
                {/* Header */}
                <div className="my-4 mx-0">
                  <h1 className="text-lg font-semibold text-[#0F3D79]">
                    Create an Ad
                  </h1>
                </div>

                {/* Form Section */}
                <div className="mb-6 relative">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="domain"
                  >
                    Select Ad Type
                  </label>
                  <div className="relative">
                    <select
                      id="domain"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-8"
                    >
                      <option>Select Product / Service</option>
                      {/* Add more options here */}
                    </select>
                    <svg
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 w-8 h-8 text-[#0F3D79] pointer-events-none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6.293 9.293a1 1 0 011.414 0L10 10.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>

                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="topicName"
                  >
                    Title of the Ad
                  </label>
                  <input
                    id="topicName"
                    type="text"
                    placeholder="Enter title of the ad"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="topicName"
                  >
                    Description
                  </label>
                  <textarea
                    id="topicName"
                    placeholder="Enter ad description"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    rows="4" // Adjust the number of rows as needed
                  ></textarea>
                </div>

                <div className="mb-6 relative">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="domain"
                  >
                    Target Audience
                  </label>
                  <div className="relative">
                    <select
                      id="domain"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-8"
                    >
                      <option>
                        Select target audience or multiple target audiences
                      </option>
                      {/* Add more options here */}
                    </select>
                    <svg
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 w-8 h-8 text-[#0F3D79] pointer-events-none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6.293 9.293a1 1 0 011.414 0L10 10.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>

                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="upload"
                  >
                    Upload Image / Video
                  </label>
                  <div className="relative flex items-center border-dashed border-2 border-gray-300 rounded p-4">
                    <input
                      type="file"
                      id="upload"
                      accept="image/*,video/*" // Accept both images and videos
                      className="absolute inset-0 opacity-0 cursor-pointer"
                      //   onChange={handleFileChange}
                    />
                    <span className="flex-1 text-gray-700">
                      {fileName ? fileName : "Select or upload image or video"}
                    </span>
                    <CloudUploadIcon className="w-6 h-6 text-blue-500" />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <button
                    className="bg-[#002357] hover:bg-blue-700 text-white font-semi py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                  >
                    Create an Ad
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateAdd;

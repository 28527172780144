import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchOneIoiProfile, ioiuserSelector } from "../../api/ioiUser";
import { toast } from "react-toastify";
import WorkIcon from "@mui/icons-material/Work";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import { fetchPostedJobs, jobsSelector } from "../../api/jobs";
import { Box, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { Interweave } from "interweave";
import {
  follwIOIProfile,
  individualUserSelector,
  unfollwIOIProfile,
} from "../../api/individualUser";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Image from "../../images/DomesticSolutions-16.jpg";
import Image1 from "../../images/AIforAI-17.png";
import Image2 from "../../images/DreamTrue-18.jpg";
import Image3 from "../../images/brodcast.jpeg";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
    wordWrap: "break-word", // For compatibility with older browsers
  },
}));

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "2px solid white",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 3,
  minWidth: 400,
  height: 200,
  textAlign: "center",
};

function IoiProfilepage() {
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const { Ioiuser_job } = useSelector(jobsSelector);
  const { current_ioi_user } = useSelector(ioiuserSelector);
  const { user, individualtoken } = useSelector(individualUserSelector);
  const { id } = useParams();
  const nav = useNavigate();
  const classes = useStyles();

  const interweaveStyles = css`
    white-space: pre-wrap; // This allows text to wrap as needed
    // You can also use 'white-space: normal;' for normal text wrapping behavior
  `;

  const images = [
    current_ioi_user?.profile_completion?.marketing_images[0],
    current_ioi_user?.profile_completion?.marketing_images[1],
    current_ioi_user?.profile_completion?.marketing_images[2],
    current_ioi_user?.profile_completion?.marketing_images[3],
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    dispatch(fetchOneIoiProfile(id));
    dispatch(fetchPostedJobs(id));
  }, [id]);

  // useEffect(() => {
  //     const interval = setInterval(nextImage, 2500); // Change image every 3 seconds (adjust as needed)

  //     return () => {
  //       clearInterval(interval); // Clear the interval when the component unmounts
  //     };
  //   }, []);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const tostPop = () => {
    toast.warning("You have already following this account", {
      position: "top-center",
      autoClose: 500,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  //console.log(current_ioi_user, "****");
  return (
    <div>
      {current_ioi_user?.profile_activate_status && (
        <section class="pt-10" style={{ fontFamily: "Lato" }}>
          <div class="container mx-auto">
            <div class="flex flex-wrap">
              <div class="w-full lg:w-[25%] px-3">
                <div class="mb-6">
                  <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden lg:h-[400px]">
                    <img
                      class="block mx-auto my-5 w-32 h-32 rounded-full"
                      src={
                        current_ioi_user?.profile_completion?.logo
                          ? current_ioi_user?.profile_completion?.logo
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                      alt=""
                    />
                    <div class="px-6 text-center">
                      <p class="text-lg font-semibold text-[#0F3D79]">
                        {current_ioi_user?.name}
                      </p>
                      <div class="flex items-center justify-center mb-4">
                        <p class="text-md font-semibold text-gray-500">
                          {current_ioi_user?.sub_category}
                        </p>
                      </div>
                      <div>
                        {user?.following?.length != 0 ? (
                          user?.following?.find((item) => {
                            return item?._id == current_ioi_user?._id;
                          }) ? (
                            <div class="text-center">
                              <div
                                class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white  hover:bg-[#0F3D79] hover:text-white transition duration-300 overflow-hidden cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(
                                    unfollwIOIProfile(
                                      user?._id,
                                      { id: current_ioi_user?._id },
                                      individualtoken
                                    )
                                  );
                                }}
                              >
                                <span className="">
                                  <CheckRoundedIcon />
                                </span>
                                <span class="relative px-3 text-lg">
                                  Following
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div class="text-center mb-2">
                              <div
                                class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white hover:bg-[#0F3D79] hover:text-white transition duration-300 overflow-hidden cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(
                                    follwIOIProfile(
                                      user?._id,
                                      { id: current_ioi_user?._id },
                                      individualtoken
                                    )
                                  );
                                }}
                              >
                                <span className="">
                                  <PersonAddAltRoundedIcon />
                                </span>
                                <span class="relative px-3 text-lg">
                                  Follow
                                </span>
                              </div>
                            </div>
                          )
                        ) : (
                          <div class="text-center mb-2">
                            <div
                              class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white hover:bg-[#0F3D79] hover:text-white transition duration-300 overflow-hidden cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                  follwIOIProfile(
                                    user?._id,
                                    { id: current_ioi_user?._id },
                                    individualtoken
                                  )
                                );
                              }}
                            >
                              <span className="">
                                <PersonAddAltRoundedIcon />
                              </span>
                              <span class="relative px-3 text-lg">Follow</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="py-5 bg-gray-100 rounded-xl overflow-hidden mb-5 hidden sm:block">
                  <Link
                    class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    // to="https://bharc.in/?page_id=189"
                    target="_blank"
                  >
                    <img className="h-20" src={Image} />
                  </Link>
                  <Link
                    class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    // to="https://bharc.in/?page_id=187"
                    target="_blank"
                  >
                    <img className="h-20" src={Image1} />
                  </Link>
                  <Link
                    class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    // to="https://bharc.in/?page_id=109"
                    target="_blank"
                  >
                    <img className="h-20" src={Image2} />
                  </Link>
                </div> */}

                <div class="py-5 bg-gray-100 rounded-xl  overflow-hidden mb-5 w-full h-[360px] hidden sm:block ">
                  <div class="flex flex-wrap justify-between">
                    <h3
                      class="text-lg font-semibold text-[#0F3D79] mb-4"
                      style={{ marginLeft: "15px", marginTop: "10px" }}
                    >
                      Projects
                    </h3>
                  </div>
                  <Link
                    class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    to="/bharc"
                  >
                    <img class="h-12 w-[135px] object-cover" src={Image3} />
                  </Link>
                  <Link
                    class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    to="/domesticSolutions"
                  >
                    <img class="h-12 " src={Image} />
                  </Link>
                  <Link
                    class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    to="/AI"
                  >
                    <img class="h-12 " src={Image1} />
                  </Link>
                  <Link
                    class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                    to="/dreamTrue"
                  >
                    <img class="h-12 " src={Image2} />
                  </Link>
                </div>
              </div>
              <div
                class="w-full lg:w-[50%] px-3"
                style={{ display: type == "" ? "block" : "none" }}
              >
                <div class="container mx-auto">
                  <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden h-[200px] md:h-[400px]">
                    <div className=" border-b border-gray-200">
                      <div className="relative text-center">
                        <img
                          className="block mx-auto rounded-lg  h-[200px] md:h-[400px]"
                          src={images[currentImageIndex]}
                          alt=""
                        />
                        <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
                          <button
                            className="text-[#0F3D79]"
                            onClick={prevImage}
                          >
                            <NavigateBeforeIcon />
                          </button>
                        </div>
                        <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
                          <button
                            className="text-[#0F3D79]"
                            onClick={nextImage}
                          >
                            <NavigateNextIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full mt-7 mb-6 lg:mb-0">
                    <div class="mb-6">
                      <div class="py-4 px-6 bg-gray-100 rounded-xl lg:h-[357px] h-full">
                        <div class="flex flex-col h-full">
                          <div class="flex justify-between items-center mb-4">
                            <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                              About
                            </h3>
                          </div>
                          <div
                            class="overflowY-auto"
                            style={{
                              maxHeight: "calc(100% - 56px)",
                              overflowX: "auto",
                              overflowY: "auto",
                            }}
                          >
                            <div class="leading-normal text-gray-500 font-medium">
                              <Interweave
                                content={
                                  current_ioi_user?.profile_completion
                                    ?.abt_company
                                }
                                customElementAttributes={{
                                  ul: { className: "custom-ul" },
                                  li: { className: "custom-li" },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="w-full lg:w-[25%] px-3"
                style={{ display: type == "" ? "block" : "none" }}
              >
                <div class="p-6 bg-gray-100 rounded-xl h-[400px]">
                  <div class="flex flex-wrap -mx-3 mb-3 justify-between items-center">
                    <div class="w-auto px-3">
                      <h4 class="text-lg text-[#0F3D79] font-semibold">
                        Interested Domains
                      </h4>
                    </div>
                  </div>
                  <div class="w-full">
                    <div class="h-full py-3 rounded-xl">
                      <div class="mb-8 -mx-4">
                        <div class="-mb-3 text-start white-space-no-wrap">
                          {current_ioi_user?.profile_completion?.key_areas.map(
                            (item, i) => {
                              return (
                                i < 6 && (
                                  <p class="px-2 pl-5 py-1 my-1 mb-1  text-gray-500 bg-gray-200 text-sm font-medium leading-6 rounded-full">
                                    {item}
                                  </p>
                                )
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-6 bg-gray-100 rounded-xl mt-6 h-[357px]">
                  <div class="flex flex-wrap -mx-3 mb-3 justify-between items-center">
                    <div class="w-auto px-3">
                      <h4 class="text-lg text-[#0F3D79] font-semibold">
                        General Information
                      </h4>
                    </div>
                  </div>

                  <div className=" flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                      Main Category :
                    </h4>
                    <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                      {current_ioi_user?.category.charAt(0).toUpperCase() +
                        current_ioi_user?.category.slice(1)}
                    </p>
                  </div>

                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                      Establishment Year :
                    </h4>
                    <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                      {current_ioi_user?.establish_year}
                    </p>
                  </div>

                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                      Classification :
                    </h4>
                    <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                      {/* {user?.profile_completion?.classified_as} */}
                      {current_ioi_user?.profile_completion?.classified_as}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap w-full bg-gray-100 rounded-xl  m-4">
                <div class="w-full p-3 lg:w-[33.33%]">
                  <h4 class="text-lg text-[#0F3D79] font-semibold py-3">
                    Additional Information
                  </h4>
                  <div className="flex mt-2">
                    {/* <img class="w-50 h-40" src={Image4} /> */}
                    <img
                      class="block mx-auto mb-5 w-40 h-40  rounded-full"
                      src={
                        current_ioi_user?.authorized_person_profile_pic
                          ? current_ioi_user?.authorized_person_profile_pic
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                      alt=""
                    />
                  </div>
                </div>

                <div class="w-full p-3 lg:w-[33.33%] mt-0 sm:mt-20">
                  <div className=" flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1 ">
                      Authorized person :
                    </h4>
                    <p class="ml-2 text-sm font-semibold text-gray-800 ">
                      {current_ioi_user?.authorized_person_name}
                    </p>
                  </div>
                  <div className=" flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1 ">
                      Designation :
                    </h4>
                    <p class="ml-2 text-sm font-semibold text-gray-800 ">
                      {current_ioi_user?.designation}
                    </p>
                  </div>
                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1 ">
                      Email :
                    </h4>
                    <p class="ml-2 text-sm font-semibold text-gray-800 ">
                      {current_ioi_user?.email}
                    </p>
                  </div>
                </div>

                <div class="p-3 w-full lg:w-[33.33%] mt-0 sm:mt-20">
                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Headquarters :
                    </h4>
                    <p class="ml-2 text-sm font-semibold text-gray-800">
                      {current_ioi_user?.situated_at?.city
                        .charAt(0)
                        .toUpperCase() +
                        current_ioi_user?.situated_at?.city.slice(1)}{" "}
                      ,{" "}
                      {current_ioi_user?.situated_at?.state
                        .charAt(0)
                        .toUpperCase() +
                        current_ioi_user?.situated_at?.state.slice(1)}{" "}
                    </p>
                  </div>

                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Country :
                    </h4>
                    <p class="ml-2 text-sm font-semibold text-gray-800">
                      {current_ioi_user?.situated_at?.country}
                    </p>
                  </div>

                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Website:
                    </h4>
                    <a
                      href={
                        current_ioi_user?.profile_completion?.website_link ||
                        "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      class="ml-2 text-sm font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis"
                    >
                      {current_ioi_user?.profile_completion?.website_link ||
                        "Website Link"}
                    </a>
                  </div>
                </div>
              </div>
              <div class="py-2 bg-gray-100 rounded-xl overflow-hidden mb-5 w-full h-[341px] block md:hidden">
                <div class="flex flex-wrap justify-between">
                  <h3
                    class="text-lg font-semibold text-[#0F3D79] mb-4"
                    style={{ marginLeft: "15px", marginTop: "10px" }}
                  >
                    Projects
                  </h3>
                </div>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/bharc"
                >
                  <img class="h-12 w-[135px] object-cover" src={Image3} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/domesticSolutions"
                >
                  <img class="h-12 " src={Image} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/AI"
                >
                  <img class="h-12 " src={Image1} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/dreamTrue"
                >
                  <img class="h-12 " src={Image2} />
                </Link>
              </div>

              {/* 
                <div class="flex flex-wrap w-full bg-gray-100 rounded-xl  m-4">
                  <div class="w-full p-3 lg:w-[50%]">
                      <h4 class="text-lg text-[#0F3D79] font-semibold py-3">
                        Additional Information
                      </h4>
                      <div className=" flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                          Authorized person :
                        </h4>
                        <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                          {current_ioi_user?.authorized_person_name}
                        </p>
                      </div>
                      <div className=" flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                          Designation :
                        </h4>
                        <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                          {current_ioi_user?.designation}
                        </p>
                      </div>
                      <div className="flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                          Email :
                        </h4>
                        <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                          {current_ioi_user?.email}
                        </p>
                      </div>
                  </div>

                  <div class="p-3 w-full lg:w-[50%]">
                      
                      <div className="flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1">
                          Headquarters :
                        </h4>
                        <p class="ml-2 text-md font-semibold text-gray-800">
                          {current_ioi_user?.situated_at?.city
                            .charAt(0)
                            .toUpperCase() +
                            current_ioi_user?.situated_at?.city.slice(1)}{" "}
                          ,{" "}
                          {current_ioi_user?.situated_at?.state
                            .charAt(0)
                            .toUpperCase() +
                            current_ioi_user?.situated_at?.state.slice(1)}{" "}
                        </p>
                      </div>

                      <div className="flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1">
                          Country :
                        </h4>
                        <p class="ml-2 text-md font-semibold text-gray-800">
                          {current_ioi_user?.situated_at?.country}
                        </p>
                      </div>

                      <div className="flex mt-2">
                        <h4 class="text-md text-gray-500 font-medium mb-1">
                          Website:
                        </h4>
                        <a
                          href={user?.profile_completion?.website_link}
                          target="_blank"
                          class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis"
                        >
                          {current_ioi_user?.profile_completion?.website_link}
                        </a>
                      </div>
                  </div>
                </div> */}

              <div
                className="w-full lg:w-[78%] px-3 relative bg-gray-100 shadow rounded-lg overflow-hidden"
                style={{ display: type == "job" ? "block" : "none" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    gap: "30px",
                    flexWrap: "wrap",
                  }}
                >
                  {Ioiuser_job?.length != 0 ? (
                    <>
                      {Ioiuser_job?.map((item, i) => {
                        return (
                          <Box
                            key={i}
                            sx={{
                              padding: "20px",
                              width: "25%",
                              borderRadius: "10px",
                              boxShadow: "2px 2px 2px 2px rgb(208,208,208)",
                              borderBottom: "7px solid black",
                            }}
                          >
                            <Box sx={{ display: "flex", marginBottom: "20px" }}>
                              <WorkIcon sx={{ fontSize: "46px" }} />
                              <Box sx={{ marginLeft: "10px" }}>
                                <p className="text-sm">
                                  {item.job_type}&nbsp; &nbsp;{" "}
                                  {item.mode_of_work}
                                </p>
                                <Typography variant="h6" component="h6">
                                  {item.designation}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                marginBottom: "20px",
                                height: "100px",
                                width: "100%",
                              }}
                              className={classes.boxContainer}
                            >
                              <Interweave
                                content={item.job_description}
                                css={interweaveStyles}
                              />
                            </Box>
                          </Box>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="text-center text-3xl  text-gray-500 ">
                        <h1>No jobs posted</h1>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default IoiProfilepage;

import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Textarea } from "@mui/joy";
import Typography from "@mui/material/Typography";
// import { updateIndividual } from "../../api/individualUser";
import {
  acceptConnectionRequest,
  declineConnectionRequest,
  follwIOIProfile,
  individualUserSelector,
  sendConnectReq,
  unfollwIOIProfile,
} from "../../api/individualUser";

// import Box from "@mui/material/Box";
import storage from "../../shared/firebaseStorage";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { nanoid } from "@reduxjs/toolkit";
import { Autocomplete, Box, Checkbox } from "@mui/material";
import { Keyareas } from "../../shared/constants";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Addtopic() {
  //
  const [visible, setVisible] = React.useState(false);
  const [selectedkeyArea, setSelectedkeyArea] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [open, setOpen] = useState(false);
  const { user, individualtoken } = useSelector(individualUserSelector);

  //
  const [fileName, setFileName] = useState(null);

  const [characterLimit1, setCharacterLimit1] = useState(
    "Your description cannot exceed 1500 characters and above."
  );

  const [characterLimit2, setCharacterLimit2] = useState(
    "Your description cannot exceed 200 characters and above."
  );

  const [Logo, SetLogo] = useState("");
  const [uploadSuccessMsg, setLogoUploadSuccess] = useState("");
  const [logoPercent, setLogoPercent] = useState(0);

  const [formData, setFormData] = useState({
    blog_category: "",
    title: "",
    description: "",
    link: "",
  });

  const dispatch = useDispatch();
  const nav = useNavigate();

  const handleDeleteClickLogo = () => {
    SetLogo("");
    setLogoUploadSuccess("");
  };

  const handleFileInputChange = (event) => {
    setLogoPercent(0);
    const newSelectedFile = event.target.files[0];
    // //console.log(newSelectedFile.name)
    // const isJpgOrPng = newSelectedFile.type === "image/jpeg" || newSelectedFile.type === "image/png" || newSelectedFile.type === "image/webp";
    const isJpgOrPng = newSelectedFile.type === "application/pdf";
    setFileName(newSelectedFile.name);
    if (!isJpgOrPng) {
      toast.warning("You can only upload PDF files!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (!isJpgOrPng) {
      const storageRef = ref(
        storage,
        `/broadcast/${nanoid() + newSelectedFile.name}`
      );

      const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);
      uploadFile.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // update progress
          setLogoPercent(percent);
          if (percent == 100)
            setLogoUploadSuccess("Poster image uploaded successfully.");
        },
        (
            err // //console.log(err),
          ) =>
          () => {
            // download url
            getDownloadURL(uploadFile.snapshot.ref).then((url) => {
              SetLogo(url);
            });
          }
      );
    }
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    if (name == "title" && value.length <= 200) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name == "description" && value.length <= 1500) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handelValue = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    preFill();
  }, [user]);

  //Autocomplete useEffect
  React.useEffect(() => {
    const keyInterst = selectedkeyArea?.map((item) => {
      return item?.title;
    });
    setSkills(keyInterst);
  }, [selectedkeyArea]);

  const preFill = () => {
    setSkills(user?.profile_completion?.skills);
    
    user?.profile_completion?.skills?.length != 0
      ? setTimeout(() => {
          setVisible(true);
        }, [1000])
      : setVisible(true);
  };

  const handelClose = () => {
    setOpen(false);
    preFill();
  };

  //Autocomplete function
  const handleKeyAreaSelection = (value) => {
    if (value.length <= 3) {
      setSelectedkeyArea([...value]);
    }
    if (value.length > 3)
      toast.warning("You can only select 3 domains", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  };

  return (
    <div className="flex justify-center  ">
      <form className="w-full sm:w-1/2 px-6 mt-5 ">
        {/* <FormControl fullWidth className="mt-5" sx={{ marginTop: "20px" }}>
          <TextField
            variant="outlined"
            type="url"
            name="link"
            label="Domain Name"
            value={formData?.link}
            onChange={handelValue}
          />
        </FormControl> */}

        <div>
          {visible ? (
            <Autocomplete
              multiple
              // style={{height:'20px'}}
              id="checkboxes-tags-demo"
              options={Keyareas}
              disableCloseOnSelect
              name={(option) => option?.title}
              getOptionLabel={(option) => option?.title}
              renderOption={(props, option, { selected }) => {
                const isOptionSelected = (selectedkeyArea || []).some(
                  (defaultOption) => defaultOption.title === option.title
                );
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 2 }}
                      checked={selected || isOptionSelected}
                    />
                    {option.title}
                  </li>
                );
              }}
              value={selectedkeyArea}
              onChange={(event, newValue) => handleKeyAreaSelection(newValue)}
              style={{ width: "100%", marginTop: "10px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Interested Domains"
                  variant="outlined"
                  required
                />
              )}
            />
          ) : (
            <></>
          )}
        </div>

        <div className="mt-5">
          <Textarea
            required
            placeholder="Title of Topic"
            name="title"
            value={formData?.title}
            onChange={handelChange}
            minRows={2}
            maxRows={4}
            endDecorator={
              <Box
                sx={{
                  display: "flex",
                  gap: "var(--Textarea-paddingBlock)",
                  pt: "var(--Textarea-paddingBlock)",
                  borderTop: "1px solid",
                  borderColor: "divider",
                  flex: "auto",
                }}
              >
                <Typography
                  level="body-xs"
                  sx={{
                    ml: "auto",
                    color: formData?.title?.length < 200 ? "black" : "red",
                  }}
                >
                  {formData?.title?.length < 200
                    ? `${formData.title.length} character(s)`
                    : characterLimit2}
                </Typography>
              </Box>
            }
            sx={{
              minWidth: 100,
            }}
          />
        </div>

        <div class="py-5">
          <div class=" flex w-full md:w-auto p-2 ">
            <h2 class="text-[#0F3D79] text-lg font-semibold">Upload file :</h2>
          </div>

          <div className="">
            <label>
              <button
                style={{ marginTop: "3%" }}
                component="label"
                role={undefined}
                tabIndex={-1}
                variant="outlined"
                color="neutral"
              >
                {Logo ? (
                  <>
                    <input
                      className="w-34"
                      type="file"
                      accept="image/*"
                      onChange={handleFileInputChange}
                    />
                    {/* <p>{fileName == null ? '' : fileName}</p> */}
                    {/* <img
                          src={Logo}
                          className="block mx-auto mb-5 w-28 h-28  "
                        /> */}
                  </>
                ) : (
                  <input
                    className="w-34"
                    type="file"
                    accept="image/*"
                    onChange={handleFileInputChange}
                  />
                )}
              </button>
            </label>
            {/* {
                  Logo && (
                    <button
                  type="button"
                  className=""
                  onClick={handleDeleteClickLogo}
                >
                  <p className="text-gray-400 hover:text-gray-700">
                    <DeleteIcon />
                  </p>
                </button>
                  )
                } */}
            <>
              {/* {logoPercent < 100 && Logo ? (
                    <span
                      className="ml-5 font-semibold"
                      style={{ color: logoPercent < 100 ? "red" : "green" }}
                    >
                      Upload {logoPercent}%
                    </span>
                  ) : (
                    <p className="font-normal" style={{ color: "green" }}>
                      {uploadSuccessMsg}
                    </p>
                  )} */}
            </>
          </div>
        </div>

        <FormControl sx={{ marginTop: "20px", marginBottom: "20px" }}>
          <Button size="large" variant="contained" type="submit">
            Submit
          </Button>
        </FormControl>
      </form>
    </div>
  );
}

export default Addtopic;

import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Recommend from "./recommendationSection";
import Profile from "./profileSection";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchIndividualProfile,
  individualUserSelector,
  recommendPeople,
  usersConnected,
  usersFollowing,
} from "../../api/individualUser";
import { ioiuserSelector, getRecommendIOI } from "../../api/ioiUser";
import { useNavigate } from "react-router";
import Section4 from "./IOIrecomendationsection";
import FreePeriod from "./Timer";
import Projects from "./projects";

export default function Index() {


  
  const {
    user,
    recommend_people,
    individualtoken,
    connected,
    following,
    isAuthenticate,
  } = useSelector(individualUserSelector);
  const { recommend_IOI, ioiUser } = useSelector(ioiuserSelector);

  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    dispatch(getRecommendIOI(user?._id));
  }, [user]);

  useEffect(() => {
    dispatch(usersConnected(user?._id));
    // dispatch(recommendPeople(user?._id));
    dispatch(usersFollowing(user?._id));
  }, [user]);

  useEffect(() => {
    // const token = localStorage.getItem("token")
    if (
      isAuthenticate &&
      !user?.profile_completion?.status &&
      user?.profile_completion?.status !== undefined
    ) {
      nav("/profile");
    }
  }, [user, isAuthenticate]);

  useEffect(() => {

    if (individualtoken) {
      dispatch(fetchIndividualProfile(individualtoken));
    }
  }, [individualtoken]);

  React.useEffect(() => {
    dispatch(getRecommendIOI(user?._id));
  }, [user]);

  return (
    <div>
      <Navbar />
      {/* <FreePeriod/> */}
      <Profile connected={connected} following={following} />
      <Section4
        recommendeIOI={recommend_IOI}
        user={user}
        individualtoken={individualtoken}
      />
      <Recommend
        recommend_sug={recommend_people}
        user={user}
        individualtoken={individualtoken}
      />
      <Projects />
    </div>
  );
}

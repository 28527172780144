import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import DvrIcon from "@mui/icons-material/Dvr";
import { individualUserSelector } from "../../api/individualUser";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Image1 from "../../images/comingsoon.png";
import FilterIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search"; // Import the search icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import a PDF icon
import WorkIcon from "@mui/icons-material/Work";
import { set } from "date-fns";
import { RWebShare } from "react-web-share";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { savePublish, unSavePublish, publishSelector } from "../../api/publish";
import nodata from "../../images/no-data.png";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
    wordWrap: "break-word", // For compatibility with older browsers
  },
}));

function MyUploads() {
  const { individualtoken, user } = useSelector(individualUserSelector);
  const { publish, currentPublish } = useSelector(publishSelector);

  const [searchQuery, setSearchQuery] = useState("");
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (user) {
  //     const reversedPublishes = (user?.publish || []).slice().reverse(); // Reverse the array to show last added topic first
  //     setPublish(reversedPublishes);
  //   }
  // }, [user]);

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  const trimText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    const trimmedText = text.substr(0, maxLength);
    return (
      trimmedText.substr(
        0,
        Math.min(trimmedText.length, trimmedText.lastIndexOf(" "))
      ) + "..."
    );
  };

  const handleSaveTopic = (publishId) => {
    if (user?.publish_saved?.some((saved) => saved._id === publishId)) {
      dispatch(unSavePublish(user?._id, publishId, individualtoken));
    } else {
      dispatch(savePublish(user?._id, publishId, individualtoken));
    }
  };

  const filteredPublish = publish?.filter((pub) => {
    // const cert = pub?.certificate_name?.toLowerCase();
    const postedByName = pub?.publish_posted_by_user?.name?.toLowerCase(); // Adjusted to get name from publish object
    const query = searchQuery?.toLowerCase();

    // Check if the certificate name or the user's name matches the search query
    const matchesSearchQuery = postedByName?.includes(query);

    // Check if the publish is posted by the current user

    return matchesSearchQuery; // Both conditions must be true
  });


  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Patent Certificates
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="#">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer 
                     `}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        General Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="#">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer 
                   
                    `}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Projects
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="#">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer
                   
                    `}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Research papers
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/MyUploads">
                  <div
                    className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("MyUploads")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        My Published Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/saved-publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Saved Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center lg:items-end">
                    <div className="flex-grow flex items-center -mt-1">
                      {/* Heading */}
                      <h2 className="text-xl font-semibold text-[#0F3D79] lg:mr-6 mb-4 lg:mb-3">
                        My Published Documents
                      </h2>
                    </div>

                    {/* Search, Filter, and Create Buttons */}
                    <div className="flex flex-col lg:flex-row justify-end items-center w-full lg:w-auto">
                      {/* Search Bar */}
                      <div className="relative w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon className="text-gray-500" />
                        </span>
                        <input
                          type="text"
                          placeholder="Search topic name"
                          className="py-2 pl-10 pr-4 w-full lg:w-[300px] rounded-xl border border-gray-300"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>

                      {/* Filter Button */}
                      <div className="w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
                        <button
                          className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full lg:w-auto"
                          type="button"
                        >
                          <FilterIcon /> Filter
                        </button>
                      </div>

                      {/* Create Button */}
                      <div className="w-full lg:w-auto">
                        <Link
                          to="/PublishCertificate"
                          className="w-full lg:w-auto"
                        >
                          <button
                            className="py-2 px-4 text-white font-semibold border rounded-xl bg-[#0F3D79] w-full lg:w-auto"
                            type="button"
                            onClick={reloadButton}
                          >
                            <AddIcon /> Create
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {filteredPublish?.length > 0 &&
                  filteredPublish.some(
                    (publish) =>
                      publish?.publish_posted_by_user?._id === user?._id
                  ) ? (
                    <>
                      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {filteredPublish
                          .filter(
                            (publish) =>
                              publish?.publish_posted_by_user?._id === user?._id
                          )
                          .map((publish) => (
                            <div className="bg-white p-6 rounded-xl shadow-md h-[400px]">
                              <NavLink
                                key={publish._id}
                                to={`/PublishView/${publish._id}`}
                              >
                                <div className="flex items-center mb-4">
                                  <img
                                    src={
                                      publish
                                        ? publish?.publish_posted_by_user
                                            ?.profile_completion?.profile_pic ||
                                          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                        : publish?.publish_posted_by_user
                                            ?.profile_completion?.profile_pic ||
                                          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                    }
                                    alt="Profile"
                                    className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-cover"
                                  />
                                  <div>
                                    <h3 className="text-[#0F3D79] text-md font-semibold">
                                      {publish?.publish_posted_by_user?.name}
                                    </h3>
                                    <p className="text-sm text-gray-500">
                                      {
                                        publish?.publish_posted_by_user
                                          ?.profile_completion?.designation
                                      }
                                    </p>
                                  </div>
                                </div>
                                <h4 className="text-[#0F3D79] font-semibold mb-2">
                                  {trimText(publish.skills[0] || "Domain", 30)}
                                </h4>
                                <p className="text-sm text-gray-600 mb-4">
                                  {trimText(publish.description || "", 50)}
                                  <a href="#" className="text-blue-600">
                                    Read more
                                  </a>
                                </p>

                                <div className="flex justify-center items-center h-32 bg-gray-100 rounded-lg">
                                  <PictureAsPdfIcon
                                    style={{ fontSize: 50 }}
                                    className="text-blue-600"
                                  />
                                </div>
                              </NavLink>
                              <div className="flex items-center mt-4 gap-x-4 md:gap-x-[10px]">
                                <RWebShare
                                  data={{
                                    text: "Check out this topic",
                                    url: `https://user.bharc.in/topic_view/${publish?._id}`,
                                    title: "Publish Share",
                                  }}
                                  onClick={() =>
                                    console.log("Shared successfully!")
                                  }
                                >
                                  <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                    Share
                                    <ShareIcon className="ml-2 text-[#346BB7]" />
                                  </button>
                                </RWebShare>

                                {user?.publish_saved?.some(
                                  (saved) => saved?._id === publish?._id
                                ) ? (
                                  <button
                                    className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                    onClick={() =>
                                      handleSaveTopic(publish?._id)
                                    }
                                  >
                                    Saved
                                    <BookmarkBorderOutlinedIcon
                                      className="ml-2"
                                      style={{
                                        fill: "white",
                                      }}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                    onClick={() =>
                                      handleSaveTopic(publish?._id)
                                    }
                                  >
                                    Save
                                    <BookmarkBorderOutlinedIcon
                                      className="ml-2"
                                      style={{
                                        fill: "#346BB7",
                                      }}
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      {/* <img
                        src={nodata}
                        alt="No Data"
                        height={200}
                        width={200}
                      /> */}
                      <p className="mt-2">No Data Available</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyUploads;

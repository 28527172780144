import React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  individualUserSelector,
  removeOneNotifi,
} from "../../api/individualUser";

const useStyles = makeStyles((theme) => ({
  customTableRow: {
    "& > .MuiTableCell-root": {
      padding: "0px", // Adjust the padding value as needed
    },
  },
}));

function AllNotifications({ notifications }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { user, individualtoken } = useSelector(individualUserSelector);

  const viewNotification = (item) => {
    dispatch(
      removeOneNotifi(item?._id, { userId: user?._id }, individualtoken)
    );
    nav(`/profile/${item?.user?._id}`);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <TableBody>
          {notifications?.map((item, i) => {
            return (
              <TableRow
                key={i}
                className={classes.customTableRow}
                sx={{ cursor: "pointer" }}
                onClick={() => viewNotification(item)}
              >
                <TableCell className="w-[20%]">
                  <img
                    className="relative w-12 h-12 m-2 mx-auto rounded-full object-cover object-right"
                    src={
                      item?.user?.profile_completion?.profile_pic
                        ? item?.user?.profile_completion?.profile_pic
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt="img"
                  />
                </TableCell>
                <TableCell className="w-[65%]">{`${item?.from} ${item?.msg}`}</TableCell>
                <TableCell>{moment(item?.date).fromNow()}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </div>
  );
}

export default AllNotifications;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";

const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

export const initialState = {
  all_individual_user: [],
  current_individual_user: {},
  lets_connect: [],
  request_received: [],
  request_sent: [],
  connected: [],
  people_to_connect: [],
  following: [],
  recommend_people: [],
  isAuthenticate: token ? true : false,
  hasError: true,
  phone_verified: false,
  individualtoken: token,
  loading: false,
  user: null,
  period: null,
  type: "people",
  adds: [],
};

export const userSlice = createSlice({
  name: "individualuser",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getAllIndividual: (state, { payload }) => {
      state.all_individual_user = payload;
    },
    getOneIndividual: (state, { payload }) => {
      state.current_individual_user = payload;
    },
    getLetsConnect: (state, { payload }) => {
      state.lets_connect = payload;
    },
    getRequestReceived: (state, { payload }) => {
      state.request_received = payload;
    },
    getRequestSent: (state, { payload }) => {
      state.request_sent = payload;
    },
    getAllConnected: (state, { payload }) => {
      state.connected = payload;
    },
    getPeopleToConnect: (state, { payload }) => {
      state.people_to_connect = payload;
    },
    getPeopleFollowing: (state, { payload }) => {
      state.following = payload;
    },
    getUserFailure: (state) => {
      state.hasError = true;
      // state.isAuthenticate = false;
    },
    getprofileError: (state) => {
      state.hasError = true;
      state.isAuthenticate = false;
    },
    getLocalStorageToken: (state, { payload }) => {
      state.individualtoken = payload;
      state.isAuthenticate = true;
    },
    isAuthenticateError: (state) => {
      state.loading = false;
      state.isAuthenticate = false;
      state.user = {};
    },
    getUserProfile: (state, { payload }) => {
      state.user = payload.user;
      // state.isAuthenticate = true;
    },
    getPhoneVerified: (state) => {
      state.phone_verified = true;
    },
    getRecommendation: (state, { payload }) => {
      state.recommend_people = payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    getPeriod: (state, { payload }) => {
      state.period = payload;
    },
    getAllAddsSuccessful: (state, { payload }) => {
      state.adds = payload.matchingAdds;
    },
  },
});

export const {
  getUserProfile,
  isAuthenticateError,
  getLoading,
  getAllIndividual,
  getOneIndividual,
  getUserFailure,
  getAuthenticate,
  getLocalStorageToken,
  getLetsConnect,
  getRequestReceived,
  getAllConnected,
  getPeopleToConnect,
  getprofileError,
  getPeopleFollowing,
  getPhoneVerified,
  getRecommendation,
  setType,
  getPeriod,
  getRequestSent,
  getAllAddsSuccessful,
} = userSlice.actions;

export const individualUserSelector = (state) => state.individualuser;

export default userSlice.reducer;

export const createIndividualUser = (values) => async (dispatch) => {
  const key = "individualuser";
  dispatch(getLoading());
  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/users/validate-user",
      values,
      config
    );
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1800,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // setTimeout(() => {
    //   dispatch(getOneIndividual(data.indUser));
    // }, 2500);
  } catch (response) {
    setTimeout(() => {
      toast.error(`${response.response.data.message}`, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }, 200);
    dispatch(getUserFailure(response));
  }
};

export const getIndividualProfile = (id) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios(
      keyUri.BACKEND_URI + `/users/get-one-individual/${id}`,
      config
    );
    dispatch(getOneIndividual(data?.indUser));
  } catch (response) {
    setTimeout(() => {
      toast.error(`${response.response.data.message}`, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }, 200);
    dispatch(getUserFailure(response));
  }
};

export const individualLogin = (values) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/users/user-sign-in",
      values,
      config
    );
    localStorage.setItem("token", data.accesToken);
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      dispatch(getLocalStorageToken(data.accesToken));
    }, 2500);
  } catch (response) {
    toast.error(`${response.response.data.message}`, {
      position: "top-center",
      autoClose: 8000,
      hideProgressBar: true,
      closeButton: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(isAuthenticateError(response.response.data.message));
  }
};

export const fetchIndividualProfile = (token) => async (dispatch) => {
  const loginConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  dispatch(getLoading());
  try {
    const { data } = await axios.get(
      keyUri.BACKEND_URI + "/users/individual-user-profile",
      loginConfig
    );
    dispatch(getUserProfile(data));
  } catch (error) {
    dispatch(getprofileError());

    dispatch(logOut());
  }
};

export const logOut = () => async (dispatch) => {
  try {
    localStorage.removeItem("token");
    dispatch(isAuthenticateError());
    window.location.href = "/";
  } catch (error) {
    dispatch(getUserFailure(error));
  }
};

export const saveJob = (id, jobId, token) => async (dispatch) => {
  const loginConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/individual-job-save/${id}`,
      jobId,
      loginConfig
    );

 
    dispatch(fetchIndividualProfile(token));

    // toast.success(`${data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeButton: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  } catch (error) {
    // //console.log(error, "error");
    // toast.warning(`${error.response.data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeButton: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  }
};

export const unsaveJob = (id, jobId, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/individual-job-unsave/${id}`,
      jobId,
      config
    );
    dispatch(fetchIndividualProfile(token));

    // toast.success(`${data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeButton: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  } catch (error) {
    // toast.warning(`${error.response.data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeButton: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  }
};

export const updateIndividual = (id, values, token) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/update-individual-user/${id}`,
      values,
      config
    );
    dispatch(fetchIndividualProfile(token));
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch ({ response }) {
    dispatch(getUserFailure(response));
    toast.warning(`${response?.response?.data?.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const phoneVerify = () => async (dispatch) => {
  dispatch(getPhoneVerified());
};

// get individual for connect request

export const letConnect = (id) => async (dispatch) => {
  try {
    if (id) {
      const { data } = await axios(
        keyUri.BACKEND_URI + `/users/individual-to-connect/${id}`,
        config
      );
      dispatch(getLetsConnect(data.connectToIndividual));
    }
  } catch (error) {
    // //console.log(error);
  }
};

// get users request

export const usersRequestReceived = (id) => async (dispatch) => {
  try {
    if (id) {
      const { data } = await axios(
        keyUri.BACKEND_URI + `/users/request-received/${id}`,
        config
      );
      console.log(data, "data recived");
      dispatch(getRequestReceived(data?.requestReceivedDetails));
    }
  } catch (error) {
    // //console.log(error);
  }
};

export const usersRequestSent = (id) => async (dispatch) => {
  try {
    if (id) {
      const { data } = await axios(
        keyUri.BACKEND_URI + `/users/request-Sent/${id}`,
        config
      );
      console.log(data, "request send");
      dispatch(getRequestSent(data?.requestSentDetails));
    }
  } catch (error) {
    // //console.log(error);
  }
};

// get users connected

export const usersConnected = (id) => async (dispatch) => {
  try {
    if (id) {
      const { data } = await axios(
        keyUri.BACKEND_URI + `/users/get-all-connected/${id}`,
        config
      );
      dispatch(getAllConnected(data.connectedUser));
    }
  } catch (error) {
    // //console.log(error);
  }
};

// get users to connect
export const usersToConnect = (id) => async (dispatch) => {
  try {
    if (id) {
      const { data } = await axios(
        keyUri.BACKEND_URI + `/users/show-people-to-connect/${id}`,
        config
      );
      dispatch(getPeopleToConnect(data.toConnect));
    }
  } catch (error) {
    // //console.log(error);
  }
};

// get users to following
export const usersFollowing = (id) => async (dispatch) => {
  try {
    if (id) {
      const { data } = await axios(
        keyUri.BACKEND_URI + `/users/following/${id}`,
        config
      );
      dispatch(getPeopleFollowing(data.followingUsers));
    }
  } catch (error) {
    // //console.log(error);
  }
};

// send connect request
export const sendConnectReq = (value, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/send-request`,
      value,
      config
    );
    dispatch(fetchIndividualProfile(token));
    // toast.success(`${data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeButton: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  } catch (error) {
    // toast.warning(`${error.response.data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeButton: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  }
};

// accept connect request

export const acceptConnectionRequest = (value, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/accept-request`,
      value,
      config
    );
    dispatch(fetchIndividualProfile(token));
    // toast.success(`${data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeButton: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  } catch (error) {
    // toast.warning(`${error.response.data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeButton: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  }
};

// reject connect request

export const declineConnectionRequest = (value, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/decline-request`,
      value,
      config
    );
    dispatch(fetchIndividualProfile(token));
    // toast.success(`${data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeButton: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  } catch (error) {
    // toast.warning(`${error.response.data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeButton: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  }
};

export const declineRequestSent = (value, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/decline-request-sent`,
      value,
      config
    );
    dispatch(fetchIndividualProfile(token));
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

//disconnect

export const diconnectIndividual = (value, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + "/users/remove-connection",
      value,
      config
    );
    dispatch(fetchIndividualProfile(token));
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 200,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 200,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const follwIOIProfile = (id, profile, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/add-IOI-to-follow/${id}`,
      profile,
      config
    );
    dispatch(fetchIndividualProfile(token));
    // toast.success(`${data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeButton: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const unfollwIOIProfile = (id, profile, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/remove-IOI-follow/${id}`,
      profile,
      config
    );
    dispatch(fetchIndividualProfile(token));
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const recommendPeople = (id) => async (dispatch) => {
  try {
    if (id) {
      const { data } = await axios.get(
        keyUri.BACKEND_URI + `/users/recommend-individual-users/${id}`,
        config
      );
      // //console.log(data, "data");
      dispatch(getRecommendation(data?.recommend_users_to_connect));
    }
    // dispatch(fetchIndividualProfile(token));
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const removeOneNotifi = (id, value, token) => async (dispatch) => {
  try {
    if (id) {
      const { data } = await axios.put(
        keyUri.BACKEND_URI + `/users/update-notification/${id}`,
        value,
        config
      );
      dispatch(fetchIndividualProfile(token));
    }
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

//deactivateIndividual
export const deactivateIndividual = (id, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/deactivate-individual-account/${id}`,
      config
    );
    dispatch(fetchIndividualProfile(token));
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: true,
      closeButton: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      dispatch(logOut());
    }, 6000);
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

// export const fetchUserAdds = (values) => async (dispatch) => {
//   try {
//     // Make an API call to fetch ads
//     const { data } = await axios.post(
//       `${keyUri.BACKEND_URI}/users/fetch-adds`, // API endpoint
//       values, // Data sent in the request body
//       config // Config includes headers (e.g., token for authentication)
//     );

//     // Dispatch action to store the ads data in Redux
//     dispatch(getAllAddsSuccessful(data));

//     // Optionally show a success toast (uncomment if needed)
//     // toast.success(data.message, {
//     //   position: "top-center",
//     //   autoClose: 1000,
//     //   hideProgressBar: true,
//     //   closeOnClick: true,
//     //   pauseOnHover: true,
//     //   draggable: true,
//     //   theme: "light",
//     // });
//   } catch (error) {
//     const errorMessage =
//       error?.response?.data?.message || "Failed to fetch ads.";

//     // Show error toast
//     toast.error(errorMessage, {
//       position: "top-center",
//       autoClose: 1000,
//       hideProgressBar: true,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       theme: "light",
//     });

//     // Dispatch failure action
//     // dispatch(getExploreFailed());
//   }
// };

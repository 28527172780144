import React, { useEffect } from "react";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIndividualProfile,
  individualUserSelector,
} from "../../api/individualUser";
import WorkIcon from "@mui/icons-material/Work";
import { NavLink, useLocation } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import  bharcImg  from "../../images/bharcimg.png"

export default function Jobapplied() {
  const { individualtoken, user, connected, request_received } = useSelector(
    individualUserSelector
  );
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(fetchIndividualProfile(individualtoken));
  }, []);

  

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/jobs">
                  <div class="flex group py-3 px-2 sm:px-8 items-center  hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Find Jobs
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/jobapplied">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center  hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("jobapplied")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Job Applied
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/jobsave">
                  <div class="flex group py-3 px-2 sm:px-8 items-center  hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Job Saved
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/JobShortlisted">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Job Shortlisted
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div class="w-full lg:w-[78%] px-3">
              <div class="h-full  py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div class="flex flex-wrap">
                  {user?.job_applied_for?.map((item, i) => (
                    // <div key={i} class="w-full md:w-[33%] p-2">
                    //   <div class="relative p-4 h-full bg-white border hover:border-gray-300 rounded-xl">
                    //     <div class="relative z-10 flex flex-col justify-between h-full">
                    //       <div class=" flex-1">
                    //         <h3 class="mb-3 text-lg font-bold font-heading text-[#0F3D79]">
                    //           {item?.designation}
                    //         </h3>
                    //         <p className="mb-2">
                    //           <span className="text-gray-500">
                    //             <LocationOnIcon />
                    //             <span className="text-sm ml-2">
                    //               {item?.job_type} , {item?.mode_of_work}
                    //             </span>
                    //           </span>
                    //         </p>
                    //         <span className="text-gray-500">
                    //           <WorkIcon />{" "}
                    //           <span className="ml-2">{item?.job_category}</span>
                    //         </span>
                    //       </div>
                    //       <div>
                    //         <NavLink to={`/joblist/${item?._id}`}>
                    //           <h4 class="text-sm float-right font-semibold bg-gray-50 py-1 hover:bg-gray-200 rounded-lg px-4 text-[#0F3D79] cursor-pointer">
                    //             View
                    //           </h4>
                    //         </NavLink>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>

                    <div class="w-full md:w-[50%] p-2">
                    <div class="relative h-full bg-white border hover:border-gray-300 rounded-xl flex flex-col p-2">
                      {/* Upper Section */}
                      <div class="h-[full] flex">
                        {/* Upper Left Section */}
                        <div class="w-[25%] lg:w-[20%] p-1">
                          <img  src={
                    item?.job_posted_by?.profile_completion?.logo
                        ? item?.job_posted_by?.profile_completion?.logo
                        : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                    } alt="Company" class="w-full h-[70px] lg:h-[70px] object-fit border border-gray-300 rounded-2xl"/>
                        </div>
                       
                        {/* Upper Right Section */}
                        <div class="w-[75%] lg:w-[80%] p-2 flex flex-col justify-center">
                          <h3 class="text-lg font-bold mb-2 text-[#0F3D79]">
                          {item?.job_posted_by?.name}
                          </h3>
                          <p className="text-gray-500">
                          {item?.job_posted_by?.sub_category}
                          </p>
                        </div>
                      </div>
                      
                      {/* Bottom Section */}
                      <div class="h-[full] px-4  flex">
                        {/* Left Section */}
                        <div class="w-[80%] pr-2 flex flex-col justify-between">
                          <div class="flex-1">
                            <h4 class="mb-2 text-lg font-bold font-heading text-[#0F3D79]">
                              {item?.designation}
                            </h4>
                            <p className="mb-2">
                              <span className="text-gray-500">
                                <LocationOnIcon />
                                <span className="text-sm ml-2">
                                  {item?.job_type}, {item?.mode_of_work}
                                </span>
                              </span>
                            </p>
                            <span className="text-gray-500 py-2">
                              <WorkIcon />
                              <span className="ml-2">
                                {item?.job_category}
                              </span>
                            </span>
                          </div>
                        </div>
                        
                        {/* Right Section */}
                        <div class="w-[20%] flex items-center justify-end">
                          <NavLink to={`/joblist/${item?._id}`}>
                            <h4 class="text-sm font-semibold bg-[#0F3D79] hover:bg-[#0C2C60] text-white rounded-lg px-4 py-2 mt-10 cursor-pointer">
                              Apply
                            </h4>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>


                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
// import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchFollowSugg, ioiuserSelector } from "../../api/ioiUser";
// import {
//   InputAdornment,
//   OutlinedInput,
//   FormControl,
//   InputLabel,
// } from "@mui/material";
// import { useDebounce } from "use-debounce";
// import Axios from "axios";
// import { keyUri } from "../../key";
// import SearchIcon from "@mui/icons-material/Search";
// import {
//   follwIOIProfile,
//   individualUserSelector,
//   unfollwIOIProfile,
// } from "../../api/individualUser";
// import { useNavigate } from "react-router";
// import {
//   Maincategory,
//   Subcategory,
//   Subcategory2,
//   Subcategory3,
//   Subcategory4,
// } from "../../shared/constants";
// import { TextField, IconButton } from "@material-ui/core";
// import { Autocomplete } from "@mui/joy";

// export default function Tab2({ type }) {
//   const dispatch = useDispatch();
//   const [search, setSearch] = useState("");
//   const [filter, setFilter] = useState();
//   const [data, setData] = useState([]);
//   const [open, setOpen] = useState(false);

//   const [debouncedText] = useDebounce(search, 1000);

//   const { follow_sug } = useSelector(ioiuserSelector);
//   console.log(follow_sug, "((((((((");

//   const { individualtoken, user } = useSelector(individualUserSelector);

//   const [category, setCategory] = useState({
//     category: "",
//   });

//   const nav = useNavigate();
//   const userId = user?._id;
//   // //console.log(userId, "id");

//   const sub_categories = [
//     ...Subcategory,
//     ...Subcategory2,
//     ...Subcategory3,
//     ...Subcategory4,
//   ];

//   useEffect(() => {
//     setData(follow_sug);
//   }, [follow_sug]);

//   useEffect(() => {
//     filter && debouncedText !== "" ? setData(filter) : setData(follow_sug);
//   }, [filter, debouncedText, userId]);

//   useEffect(() => {
//     Axios.get(
//       keyUri.BACKEND_URI + `/users/all-ioi?search=${debouncedText}`
//     ).then(({ data }) => {
//       setFilter(data?.ioiUsers);
//     });
//   }, [dispatch, debouncedText]);

//   useEffect(() => {
//     if (debouncedText != "") {
//       if (category.category != "") {
//         setData([]);
//         setCategory({ category: "" });
//       }
//     }
//   }, [debouncedText]);

//   const handleFormCategoryChange = (sub_category) => {
//     setCategory({ category: sub_category.value });
//     // setData(follow_sug.filter((item)=>{
//     //   return item._id !== userId && item?.category == category.category
//     // }))
//   };

//   useEffect(() => {
//     setData(
//       follow_sug.filter((item) => {
//         return item._id !== userId && item?.sub_category == category.category;
//       })
//     );
//   }, [category.category]);

//   useEffect(() => {
//     dispatch(fetchFollowSugg(userId));
//   }, [userId]);

//   return (
//     <div style={{ fontFamily: "Lato" }}>
//       <section class="py-0">
//         <div class="container px-4 mx-auto">
//           <div className="mb-6 w-full lg:flex justify-end">
//             <FormControl
//               className="w-full sm:w-[250px] lg:w-[250px]"
//               style={{ marginTop: "10px", marginRight: "15px" }}
//             >
//               <Autocomplete
//                 disablePortal
//                 id="combo-box-demo"
//                 placeholder="Select Category"
//                 disableClearable
//                 options={sub_categories}
//                 name="category"
//                 onChange={(event, newValue) => {
//                   handleFormCategoryChange(newValue);
//                 }}
//                 value={category.category}
//                 renderInput={(params) => (
//                   <TextField {...params} label="Select Category" />
//                 )}
//               />
//             </FormControl>

//             <TextField
//               className="w-full sm:w-[250px] lg:w-[250px]"
//               label="Search by Name"
//               size="small"
//               variant="outlined"
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment>
//                     <IconButton>
//                       <SearchIcon />
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//               onChange={(e) => setSearch(e.target.value)}
//               style={{ marginTop: "10px" }}
//             />
//           </div>
//           <div class="flex flex-wrap -m-4">
//             {data?.map((item, i) => {
//               if (item?.profile_activate_status)
//                 return (
//                   <div
//                     class="w-full lg:w-[33%] p-2 cursor-pointer"
//                     key={i}
//                     onClick={() => nav(`/view-ioiprofile/${item?._id}`)}
//                   >
//                     <div class="relative p-2 bg-white shadow rounded-xl">
//                       {/* <button class="absolute top-0 right-0 m-8 inline-block text-gray-400 hover:text-gray-500">
//                       <svg
//                         width="14"
//                         height="14"
//                         viewbox="0 0 14 14"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           d="M8.41 6.99994L12.71 2.70994C12.8983 2.52164 13.0041 2.26624 13.0041 1.99994C13.0041 1.73364 12.8983 1.47825 12.71 1.28994C12.5217 1.10164 12.2663 0.99585 12 0.99585C11.7337 0.99585 11.4783 1.10164 11.29 1.28994L7 5.58994L2.71 1.28994C2.5217 1.10164 2.2663 0.99585 2 0.99585C1.7337 0.99585 1.4783 1.10164 1.29 1.28994C1.1017 1.47825 0.995908 1.73364 0.995908 1.99994C0.995908 2.26624 1.1017 2.52164 1.29 2.70994L5.59 6.99994L1.29 11.2899C1.19627 11.3829 1.12188 11.4935 1.07111 11.6154C1.02034 11.7372 0.994202 11.8679 0.994202 11.9999C0.994202 12.132 1.02034 12.2627 1.07111 12.3845C1.12188 12.5064 1.19627 12.617 1.29 12.7099C1.38296 12.8037 1.49356 12.8781 1.61542 12.9288C1.73728 12.9796 1.86799 13.0057 2 13.0057C2.13201 13.0057 2.26272 12.9796 2.38458 12.9288C2.50644 12.8781 2.61704 12.8037 2.71 12.7099L7 8.40994L11.29 12.7099C11.383 12.8037 11.4936 12.8781 11.6154 12.9288C11.7373 12.9796 11.868 13.0057 12 13.0057C12.132 13.0057 12.2627 12.9796 12.3846 12.9288C12.5064 12.8781 12.617 12.8037 12.71 12.7099C12.8037 12.617 12.8781 12.5064 12.9289 12.3845C12.9797 12.2627 13.0058 12.132 13.0058 11.9999C13.0058 11.8679 12.9797 11.7372 12.9289 11.6154C12.8781 11.4935 12.8037 11.3829 12.71 11.2899L8.41 6.99994Z"
//                           fill="currentColor"
//                         ></path>
//                       </svg>
//                     </button> */}
//                       <img
//                         class="w-32 h-32 p-1 mb-2 mx-auto rounded-full border border-indigo-50"
//                         src={
//                           item?.profile_completion?.logo
//                             ? item?.profile_completion?.logo
//                             : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
//                         }
//                         alt=""
//                       />
//                       <h3 className="text-center font-semibold text-lg text-[#0F3D79] whitespace-nowrap overflow-hidden text-ellipsis ">
//                         {item?.name}
//                       </h3>
//                       <p class="text-center text-sm text-gray-500   py-3 whitespace-nowrap overflow-hidden text-ellipsis">
//                         {item?.sub_category}
//                       </p>
//                       {user?.following?.find((foll) => {
//                         return foll?._id === item?._id;
//                       }) ? (
//                         <div class="text-center">
//                           <a
//                             class="relative group inline-block py-2 px-7 border font-semibold text-[#0F3D79] rounded-full bg-white hover:bg-[#0F3D79] hover:text-white transition duration-300 overflow-hidden cursor-pointer"
//                             onClick={(e) => {
//                               e.stopPropagation();
//                               dispatch(
//                                 unfollwIOIProfile(
//                                   user?._id,
//                                   { id: item?._id },
//                                   individualtoken
//                                 )
//                               );
//                             }}
//                           >
//                             <span className="">
//                               <CheckRoundedIcon />
//                             </span>
//                             <span class="relative px-3 text-lg">Following</span>
//                           </a>
//                         </div>
//                       ) : (
//                         <div class="text-center">
//                           <div
//                             class="relative group inline-block py-2 px-7 font-semibold border text-[#0F3D79] rounded-full bg-white hover:bg-[#0F3D79] hover:text-white transition duration-300 overflow-hidden cursor-pointer"
//                             onClick={(e) => {
//                               e.stopPropagation();
//                               dispatch(
//                                 follwIOIProfile(
//                                   user?._id,
//                                   { id: item?._id },
//                                   individualtoken
//                                 )
//                               );
//                             }}
//                           >
//                             <span className="">
//                               <PersonAddAltRoundedIcon />
//                             </span>
//                             <span class="relative px-3 text-lg">Follow</span>
//                           </div>
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 );
//             })}
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useDispatch, useSelector } from "react-redux";
import { fetchFollowSugg, ioiuserSelector } from "../../api/ioiUser";
import { InputAdornment, FormControl } from "@mui/material";
import { useDebounce } from "use-debounce";
import Axios from "axios";
import { keyUri } from "../../key";
import SearchIcon from "@mui/icons-material/Search";
import {
  follwIOIProfile,
  individualUserSelector,
  unfollwIOIProfile,
} from "../../api/individualUser";
import { useNavigate } from "react-router";
import {
  Subcategory,
  Subcategory2,
  Subcategory3,
  Subcategory4,
} from "../../shared/constants";
import { TextField, IconButton } from "@material-ui/core";
import { Autocomplete } from "@mui/joy";
import premiumlog from '../../images/premiumlogo.png'

export default function Tab2({ type }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const [debouncedText] = useDebounce(search, 1000);

  const { follow_sug } = useSelector(ioiuserSelector);
  const { individualtoken, user } = useSelector(individualUserSelector);

  const [category, setCategory] = useState({
    category: "",
  });

  const nav = useNavigate();
  const userId = user?._id;

  const sub_categories = [
    ...Subcategory,
    ...Subcategory2,
    ...Subcategory3,
    ...Subcategory4,
  ];

  useEffect(() => {
    setData(follow_sug);
  }, [follow_sug]);

  useEffect(() => {
    filter && debouncedText !== "" ? setData(filter) : setData(follow_sug);
  }, [filter, debouncedText, userId]);

  useEffect(() => {
    Axios.get(
      keyUri.BACKEND_URI + `/users/all-ioi?search=${debouncedText}`
    ).then(({ data }) => {
      setFilter(data?.ioiUsers);
    });
  }, [dispatch, debouncedText]);

  useEffect(() => {
    if (debouncedText !== "") {
      if (category.category !== "") {
        setData([]);
        setCategory({ category: "" });
      }
    }
  }, [debouncedText]);

  const handleFormCategoryChange = (sub_category) => {
    setCategory({ category: sub_category.value });
  };

  useEffect(() => {
    setData(
      follow_sug.filter((item) => {
        return item._id !== userId && item?.sub_category === category.category;
      })
    );
  }, [category.category]);

  useEffect(() => {
    dispatch(fetchFollowSugg(userId));
  }, [userId]);

  return (
    <div style={{ fontFamily: "Lato" }}>
      <section className="py-0">
        <div className="container mx-auto">
          {/* Header and input fields container */}
          <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
            {/* Header section */}
            <h1 className="text-lg font-semibold text-[#0F3D79] ">
              Explore Organisations 
            </h1>

            {/* Input fields section */}
            <div className="flex flex-col lg:flex-row flex-1 justify-end items-center gap-4 lg:gap-0 lg:space-x-4 mt-4 lg:mt-0">
              <FormControl className="w-full sm:w-[250px] lg:w-auto">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  placeholder="Select Category"
                  disableClearable
                  options={sub_categories}
                  name="category"
                  onChange={(event, newValue) => {
                    handleFormCategoryChange(newValue);
                  }}
                  value={category.category}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Category"
                      variant="outlined"
                      className="w-full"
                    />
                  )}
                />
              </FormControl>

              {/* <TextField
                className="w-full sm:w-[250px] lg:w-auto"
                label="Search by Name"
                size="small"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearch(e.target.value)}
              /> */}

<div className="relative w-full lg:w-60">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon className="text-gray-500" />
                </span>
                <input
                  type="text"
                  placeholder="Search by name"
                  className="py-2 pl-10 pr-4 w-full rounded-xl border border-gray-300"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -m-4">
            {data?.map((item, i) => {
              if (item?.profile_activate_status)
                return (
                  <div
                    className="w-full lg:w-1/4 p-2 cursor-pointer"
                    key={i}
                    onClick={() => nav(`/view-ioiprofile/${item?._id}`)}
                  >
                    <div className="relative p-2 bg-white shadow rounded-xl">
                      {(item?.period === 2 || item?.period === 3) && (
                        <img
                          src={premiumlog} // Replace with the actual URL or path of your top-right image
                          alt="icon"
                          className="absolute top-0 right-2 w-10 h-10" // Adjust width and height as needed
                        />
                      )}
                      <img
                        className="w-24 h-24 mx-auto object-cover rounded-full border border-gray-200"
                        src={
                          item?.profile_completion?.logo
                            ? item?.profile_completion?.logo
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.pn"
                        }
                        alt=""
                      />
                      <h3 className="text-center font-semibold text-lg text-[#0F3D79] whitespace-nowrap overflow-hidden text-ellipsis">
                        {item?.name}
                      </h3>
                      <p className="text-center text-sm text-gray-500 py-3 whitespace-nowrap overflow-hidden text-ellipsis">
                        {item?.sub_category}
                      </p>
                      {user?.following?.find(
                        (foll) => foll?._id === item?._id
                      ) ? (
                        <div className="text-center">
                          <a
                            className="relative group inline-block py-2 px-7 border font-semibold text-[#0F3D79] rounded-[5px] bg-white hover:bg-[#0F3D79] hover:text-white transition duration-300 overflow-hidden cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(
                                unfollwIOIProfile(
                                  user?._id,
                                  { id: item?._id },
                                  individualtoken
                                )
                              );
                            }}
                          >
                            <span>
                              <CheckRoundedIcon />
                            </span>
                            <span className="relative px-3 text-lg">
                              Following
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div className="text-center">
                          <div
                            className="relative group inline-block py-2 px-7 font-semibold border text-[#0F3D79] rounded-[5px] bg-white hover:bg-[#0F3D79] hover:text-white transition duration-300 overflow-hidden cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(
                                follwIOIProfile(
                                  user?._id,
                                  { id: item?._id },
                                  individualtoken
                                )
                              );
                            }}
                          >
                            <span>
                              <PersonAddAltRoundedIcon />
                            </span>
                            <span className="relative px-3 text-lg">
                              Follow
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

// import React, { useEffect } from "react";
// import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   follwIOIProfile,
//   individualUserSelector,
//   unfollwIOIProfile,
//   usersFollowing,
// } from "../../api/individualUser";
// import { useNavigate } from "react-router";
// import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

// function MyFollowing() {
//   const dispatch = useDispatch();
//   const { following, user, individualtoken } = useSelector(
//     individualUserSelector
//   );
//   const nav = useNavigate();

//   useEffect(() => {
//     dispatch(usersFollowing(user?._id));
//   }, [user]);

//   return (
//     <div>
//       <section class=" mt-5 py-0" style={{ fontFamily: "Lato" }}>
//         <div class="container px-4 mx-auto">
//           <div className="w-full flex justify-end"></div>
//           <div class="flex flex-wrap -m-4">
//             {following?.map((item, i) => {
//               if(item?.profile_activate_status)
//               return (
//                 <div
//                   class="w-full lg:w-[33%] p-2 cursor-pointer"
//                   key={i}
//                   onClick={() => nav(`/view-ioiprofile/${item?._id}`)}
//                 >
//                   <div class="relative p-2 bg-white shadow rounded-xl">

//                     <img
//                       class="w-32 h-32 p-1 mb-2 mx-auto rounded-full border border-indigo-50"
//                       src={
//                         item?.profile_completion?.logo
//                           ? item?.profile_completion?.logo
//                           : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
//                       }
//                       alt=""
//                     />
//                     <h3 class="text-center font-semibold text-lg text-[#0F3D79] whitespace-nowrap overflow-hidden text-ellipsis">
//                       {item?.name}
//                     </h3>
//                     <p class="mb-5 text-center text-sm text-gray-500 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
//                     {item?.sub_category}
//                     </p>
//                     {user?.following?.find((foll) => {
//                       return foll?._id === item?._id;
//                     }) ? (
//                       <div class="text-center">
//                         <a
//                           class="relative group inline-block py-2 px-7 border font-semibold text-blue-900 hover:hover:bg-[#0F3D79] hover:text-white rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             dispatch(
//                               unfollwIOIProfile(
//                                 user?._id,
//                                 { id: item?._id },
//                                 individualtoken
//                               )
//                             );
//                           }}
//                         >
//                           <span className="">
//                             <PersonRemoveIcon />
//                           </span>
//                           <span class="relative px-3 text-lg">Unfollow</span>
//                         </a>
//                       </div>
//                     ) : (
//                       <div class="text-center">
//                         <div
//                           class="relative group inline-block py-2 px-7 font-semibold text-blue-900 hover:hover:bg-[#0F3D79] hover:text-white rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             dispatch(
//                               follwIOIProfile(
//                                 user?._id,
//                                 { id: item?._id },
//                                 individualtoken
//                               )
//                             );
//                           }}
//                         >
//                           <span className="">
//                             <PersonAddAltRoundedIcon />
//                           </span>
//                           <span class="relative px-3 text-lg">Follow</span>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }

// export default MyFollowing;

import React, { useEffect } from "react";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { useDispatch, useSelector } from "react-redux";
import {
  follwIOIProfile,
  individualUserSelector,
  unfollwIOIProfile,
  usersFollowing,
} from "../../api/individualUser";
import { useNavigate } from "react-router";
import { FormControl, InputAdornment } from "@mui/material";
import { TextField, IconButton } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete } from "@mui/joy";
import {
  Subcategory,
  Subcategory2,
  Subcategory3,
  Subcategory4,
} from "../../shared/constants";

import premiumlog from "../../images/premiumlogo.png";

function MyFollowing() {
  const dispatch = useDispatch();
  const { following, user, individualtoken } = useSelector(
    individualUserSelector
  );
  const nav = useNavigate();

  const sub_categories = [
    ...Subcategory,
    ...Subcategory2,
    ...Subcategory3,
    ...Subcategory4,
  ];
  const [category, setCategory] = React.useState({ category: "" });
  const [search, setSearch] = React.useState("");

  useEffect(() => {
    dispatch(usersFollowing(user?._id));
  }, [user]);

  const handleFormCategoryChange = (sub_category) => {
    setCategory({ category: sub_category.value });
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <section className="py-0">
        <div className="container  mx-auto">
          {/* Header and input fields container */}
          <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
            {/* Header section */}
            <h1 className="text-lg font-semibold text-[#0F3D79] ">Following</h1>

            {/* Input fields section */}
            <div className="flex flex-col lg:flex-row flex-1 justify-end items-center gap-4 lg:gap-0 lg:space-x-4 mt-4 lg:mt-0">
              <FormControl className="w-full sm:w-[250px] lg:w-auto">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  placeholder="Select Category"
                  disableClearable
                  options={sub_categories}
                  name="category"
                  onChange={(event, newValue) => {
                    handleFormCategoryChange(newValue);
                  }}
                  value={category.category}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Category"
                      variant="outlined"
                      className="w-full"
                    />
                  )}
                />
              </FormControl>

              {/* <TextField
                className="w-full sm:w-[250px] lg:w-auto"
                label="Search by Name"
                size="small"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearch(e.target.value)}
              /> */}

              <div className="relative w-full lg:w-60">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon className="text-gray-500" />
                </span>
                <input
                  type="text"
                  placeholder="Search by name"
                  className="py-2 pl-10 pr-4 w-full rounded-xl border border-gray-300"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -m-4">
            {following?.map((item, i) => {
              if (item?.profile_activate_status)
                return (
                  <div
                    className="w-full lg:w-1/4 p-2 cursor-pointer"
                    key={i}
                    onClick={() => nav(`/view-ioiprofile/${item?._id}`)}
                  >
                    <div className="relative p-2 bg-white shadow rounded-xl">
                      {(item?.period === 2 || item?.period === 3) && (
                        <img
                          src={premiumlog} // Replace with the actual URL or path of your top-right image
                          alt="icon"
                          className="absolute top-0 right-2 w-10 h-10" // Adjust width and height as needed
                        />
                      )}
                      <img
                        className="w-24 h-24 mx-auto object-cover rounded-full border border-gray-200"
                        src={
                          item?.profile_completion?.logo
                            ? item?.profile_completion?.logo
                            : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                        }
                        alt=""
                      />
                      <h3 className="text-center font-semibold text-lg text-[#0F3D79] whitespace-nowrap overflow-hidden text-ellipsis">
                        {item?.name}
                      </h3>
                      <p className="mb-5 text-center text-sm text-gray-500 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                        {item?.sub_category}
                      </p>
                      {user?.following?.find(
                        (foll) => foll?._id === item?._id
                      ) ? (
                        <div className="text-center">
                          <a
                            className="relative group inline-block py-2 px-7 border font-semibold text-blue-900 hover:bg-[#0F3D79] hover:text-white rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(
                                unfollwIOIProfile(
                                  user?._id,
                                  { id: item?._id },
                                  individualtoken
                                )
                              );
                            }}
                          >
                            <span className="">
                              <PersonRemoveIcon />
                            </span>
                            <span className="relative px-3 text-lg">
                              Unfollow
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div className="text-center">
                          <div
                            className="relative group inline-block py-2 px-7 font-semibold text-blue-900 hover:bg-[#0F3D79] hover:text-white rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(
                                follwIOIProfile(
                                  user?._id,
                                  { id: item?._id },
                                  individualtoken
                                )
                              );
                            }}
                          >
                            <span className="">
                              <PersonAddAltRoundedIcon />
                            </span>
                            <span className="relative px-3 text-lg">
                              Follow
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyFollowing;
